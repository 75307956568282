import shopList from "./shopList"
import users from "./users"
import shopProfile from './shopProfile'
import shopInfo from './shopInfo'
import userInfo from './userInfo'
import payment from './payment'

const en = {
    shopList,
    users,
    shopProfile,
    shopInfo,
    userInfo,
    payment,
}

export default en
