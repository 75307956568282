import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, isPlatform,useIonViewDidEnter,useIonViewWillEnter } from '@ionic/react';
import { addCircle, chevronForwardOutline, cubeOutline, homeOutline, walletOutline } from 'ionicons/icons';
import { useObserver} from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useStores } from '../stores';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-slideshow-image/dist/styles.css'
import liff from '@line/liff';

const DATE_FORMAT = 'DD.MM.YYYY'

interface IShopProps {
  from?: string;
  shop?: any;
}

interface IProfileImage {
  filepath: string;
  webviewPath?: string;
}

const editorStateAsJSONString = `{"entityMap":{}, "blocks":[{"key": "1qh1g", "text": "Header", "type": "header-two", "depth":0,"inlineStyleRanges":[], "entityRanges":[],"data":{}},{"key": "fthtl", "text":"text text text", "type": "unstyled", "depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}`;
const zoomInProperties = {
  indicators: true,
  scale: 1.4
}
const Campaign: React.FC<any> = ({match}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<IShopProps>()
  const { userStore } = useStores()
  const props = location?.state
  useEffect(() => {
  }, [])
  useIonViewDidEnter( async () => {
    if(liff.isInClient()){
      await liff.init({liffId:"1657011302-r7OPQ45d"}); 
      if (liff.isLoggedIn()) {
        let getProfile = await liff.getProfile();
        const params:any = new URLSearchParams(location.search);
          // alert(params.get("children"));
        if(params && params.get("id") && params.get("children")){
          let camapaign = await userStore.getCampaign(params.get("id"));
          // alert(JSON.stringify(camapaign.children[Number(params.get("children"))]));
          userStore.auditProduct({
            id:params.get("id"),
            lineId:getProfile.userId,
            index:params.get("children"),
          });
          // alert(params.get("children"));
          let children = camapaign.children[Number(params.get("children"))];
          window.location.href = children.url;
          window.open('', '_self', ''); 
        }
      }else{
        liff.login();
      }
    }else{
      const queryString = decodeURIComponent(window.location.search).replace("?liff.state=", "");
      const params = new URLSearchParams(queryString);
      if(params && params.get("id") && params.get("children")){
        userStore.auditProduct({
          id:params.get("id"),
          lineId:"PC",
          index:params.get("children"),
        });
        let camapaign = await userStore.getCampaign(params.get("id"));
        let children = camapaign.children[Number(params.get("children"))];
        window.location.href = children.url;
        window.open('', '_self', ''); 
      }
      // alert(JSON.stringify(params))
    }    
  });

  
  return useObserver(() => (
    <IonPage className="content-container">
      <IonContent className="">
        <span>รอสักครู่...</span>
      </IonContent>
    </IonPage>
  ));
};

export default Campaign;

