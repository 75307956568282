const userInfo = {
    "shop": "Shop",
    "userInfo": "User Info",
    "personalInfo": "Personal Information",
    "fullName": "Full Name",
    "nameSurname": "Name Surname",
    "email": "Email",
    "phoneNo": "Phone Number",
}

export default userInfo
