import React from 'react';
import UserStore from './userStore'
import TranslationStore from './translationStore'
import EventStore from './eventStore'

class Stores {
    userStore: UserStore
    translationStore: TranslationStore
    eventStore: EventStore
    constructor() {
        this.userStore = new UserStore()
        this.translationStore = new TranslationStore()
        this.eventStore = new EventStore()
    }
}

const StoresContext = React.createContext(new Stores())

export const useStores = () => React.useContext(StoresContext)


