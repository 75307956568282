import { observable, action, toJS,makeAutoObservable} from 'mobx'

class EventStore {

    constructor() {
        makeAutoObservable(this)
    } 

    @observable alertShow = false
    @observable SaveSentAlertShow = false
    @observable alertTitle?= ''
    @observable alertMsg?= ''
    @observable alertProps = {}

    @observable loadingShow = false
    @observable loadingMsg?= ''

    @observable showPincode = false
    @observable isLandscape = false;
    @observable google: any;

    @observable isAuthen = false

    @observable isShowPic = false
    @observable isShowTab = false

    @observable sortingValue = ''


    @action hideAlert() {
        this.alertShow = false
        this.SaveSentAlertShow=false
        this.clearAlert()
    }

    @action showAlert(title?: string, msg?: string, props?: any) {
        this.alertShow = true
        this.alertTitle = title
        this.alertMsg = msg
        this.alertProps = props
    }

    @action showSaveAlert(title?: string, msg?: string, props?: any) {
        this.SaveSentAlertShow = true
        this.alertTitle = title
        this.alertMsg = msg
        this.alertProps = props
    }

    @action clearAlert() {
        this.SaveSentAlertShow=false
        this.alertShow = false
        this.alertTitle = ''
        this.alertMsg = ''
        this.alertProps = {}
    }

    @action hideLoading() {
        this.loadingShow = false
        this.clearLoading()
    }

    @action showLoading(msg?: string) {
        this.loadingShow = true
        this.loadingMsg = msg

    }

    @action clearLoading() {
        this.loadingShow = false
        this.loadingMsg = ''
    }
    @action hidePinCode() {
        this.showPincode = false;
    }

    @action setLandScape(isLand:boolean){
        this.isLandscape = isLand;
    }
    @action showAuthen(){
        this.isAuthen = true;
    }
    @action hideAuthen() {
        this.isAuthen = false;
    }
    @action showTab() {
        this.isShowTab = true;
    }
    @action hideTab() {
        this.isShowTab = false;
    }
    @action canShowTab() {
        
       return this.isShowTab;
    }

    @action
    assignSortingValue = (value: any) => {
        this.sortingValue = value
    }
}

export default EventStore