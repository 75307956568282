import axios from 'axios';
import moment from 'moment';
import { DB } from '../utils/db';

// const endpoint = "http://localhost:3000/v1/";
const endpoint = "/v1/";
// const endpoint = "https://15e3523dae32.ap.ngrok.io/v1/"

const timeout = 150000;
var config = {
    timeout: timeout,
    headers: {
        Authorization: "Basic VnpOYWEyUjVlSEZPVmpWcg==",
        Accept: "application/json",
        "Content-Type": "application/json",
        auth: {
            username: "admax",
            password: "admaxqwerty123456"
          }
    }
};

export async function login(param: any) {
    let requestURL = `${endpoint}auth/login`
    try {
        const response = await axios.post(requestURL, param,{ ...config})
        console.log(response.data)
        if (response && response.data) {
            return response.data
        }
    } catch (e) {
        console.log(e.response)
        if (e.response.status === 401) {

        } else {

        }
        return false
    }
}
export async function get(path:string, params:any) {
    let requestURL = `${endpoint}${path}`
    let setHeaders = { ...config.headers }
    let newConfig = { ...config,params:undefined};
    if (params) {
        newConfig.params = params;
    }
    let token = await DB.getObject("token");
    setHeaders.Authorization = `Bearer ${token}`
    try {
        const response = await axios.get(requestURL, { ...newConfig, headers: setHeaders })
        console.log("getBearer", response.data)
        if (response && response.data) {
            return response.data
        }
    } catch (e) {
        console.log(e)
        if (e.response?.status===401){
            window.location.replace("/");
        }else{

        }
        return false
    }
}
export async function getBasic(path:string, params:any) {
    let requestURL = `${endpoint}${path}`
    let setHeaders = { ...config.headers }
    let newConfig = { ...config,params:undefined};
    if (params) {
        newConfig.params = params;
    }
    
    try {
        const response = await axios.get(requestURL, { ...newConfig, headers: setHeaders })
        console.log("getBasic", response.data)
        if (response && response.data) {
            return response.data
        }
    } catch (e) {
        console.log(e)
        if (e.response?.status===401){
            window.location.replace("/");
        }else{

        }
        return false
    }
}

export async function post(path:string, params:any) {
    let requestURL = `${endpoint}${path}`
    let token = await DB.getObject("token");
    let setHeaders = { ...config.headers }
    try {
        setHeaders.Authorization = `Bearer ${token}`
        const response = await axios.post(requestURL, params,{ ...config, headers: setHeaders })
        console.log(response.data)
        if (response && response.data) {
            return response.data
        }
    } catch (e) {
        console.log(e.response)
        if (e.response.status === 401) {
            window.location.replace("/");
        } else {

        }
        return false
    }
}
export async function postBasic(path:string, params:any) {
    let requestURL = `${endpoint}${path}`
    let setHeaders = { ...config.headers }
    try {
        const response = await axios.post(requestURL, params,{ ...config, headers: setHeaders })
        console.log(response.data)
        if (response && response.data) {
            return response.data
        }
    } catch (e) {
        console.log(e.response)
        if (e.response.status === 401) {
            window.location.replace("/");
        } else {

        }
        return false
    }
}
export async function uploadFile(data:any) {
    let requestURL = `${endpoint}upload/logo`
    let token = await DB.getObject("token");
    let setHeaders = { ...config.headers }
    try {
        setHeaders.Authorization = `Bearer ${token}`
        const formData = new FormData(); 
        let blob = await fetch(data.file).then(r => r.blob());
        // Update the formData object 
        formData.append( 
            "files", 
            blob, 
            data.fileName 
        ); 
       
        const response = await axios.post(requestURL, formData,{ ...config, headers: setHeaders });
        return response.data;
    } catch (e) {
        console.log(e)
        if (e.response.status === 401) {
            window.location.replace("/");
        } else {

        }
        return false
    }
}
