const shopProfile = {
    "shopCode": "Shop Code",
    "shopAdmin": "Shop Admin",
    "setting": "Setting",
    "shopInfo": "Shop Information",
    "paymentMethods": "Payment Methods",
    "shippingMethods": "Shipping Methods",
}

export default shopProfile
