import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { homeOutline, pricetagOutline, timeOutline } from "ionicons/icons";
import { Provider,observer} from "mobx-react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { useStores } from "./stores";
import "./theme/scss/styles.scss";
/* Theme variables */
import "./theme/variables.css";
import { useEffect } from "react";
import Campaign from "./pages/Campaign";
import MyDeal from './pages/MeDeal';
import RegisterStaff from './pages/RegisterStaff';

const App: React.FC =  observer(() => {
  const { t, i18n } = useTranslation();
  const { translationStore, userStore, eventStore} = useStores();
  
  translationStore.lang === "en" && require("./App.css");
  translationStore.lang === "th" && require("./App_TH.css");

  const tabs = (
    <IonTabs>
      <IonRouterOutlet>

        <Route exact path="/camapaign" component={Campaign} />
        <Route exact path="/myDeal" component={MyDeal} />
        <Route exact path="/registerStaff" component={RegisterStaff} />
        

        {/* <Route exact path="/">
          <Redirect to="/signIn" />
        </Route> */}
      </IonRouterOutlet>

      <IonTabBar slot="bottom" style={{ display: eventStore.isShowTab ? "":"none" }} className="tab-bar-bottom">
        <IonTabButton tab="MyOrder" href={`/MyOrder`} className="tab-bar-btn">
          <IonIcon icon={timeOutline} />
          <IonLabel>{t("การสั่งซื้อ")}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )

  const renderComponent = () => {
    return tabs
  }

  useEffect(() => {
    // userStore.initUser();
    initUser();
  })
  const initUser = async ()=>{
    await userStore.initUser();
  }

  return (
    <IonApp>
      <Provider {...useStores}>
        <IonReactRouter>
          {renderComponent()}
        </IonReactRouter>
      </Provider>
    </IonApp>
  );
});

export default App;
