import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from 'i18next-http-backend'

import EN from "./en"
import TH from "./th"

i18n
// .use(Backend)
.use(initReactI18next)
.init({
    lng: 'th',
    fallbackLng: 'th',
    // debug: false,
    // ns: ['namespace'],
    resources: {
        en: {
            translation: EN
        },
        th: {
            translation: TH
        }
    },
    react: {
        useSuspense: false,
        // wait: false,
    },
})

export default i18n