const shopProfile = {
    "shopCode": "รหัสร้านค้า",
    "shopAdmin": "แอดมินร้านค้า",
    "setting": "ตั้งค่า",
    "shopInfo": "ข้อมูลร้านค้า",
    "paymentMethods": "วิธีการชำระเงิน",
    "shippingMethods": "วิธีการจัดส่งสินค้า",
}

export default shopProfile
