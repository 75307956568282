import { observable, action, toJS,makeAutoObservable} from "mobx";
import { login,postBasic, get, getBasic} from '../api'
import { DB } from '../utils/db';

export default class UserStore {
  
  constructor() {
    makeAutoObservable(this)
  }
  @observable user:any = {};
  @observable lineUser:any = {};

  @action async customerSignIn(data:any,shopId:string) {
    let res = await postBasic(`shops/${shopId}/createCustomerFromLine`,data);
    this.lineUser = data;
  }
  @action async auditProduct(data:any) {
    let res = await postBasic(`campaign/auditCampaign`,data);
    return res;
  }
  @action async myDeal(lineId:any) {
    let res = await postBasic(`campaign/myDeal`,{lineId:lineId});
    return res;
  }
  
  @action async getCampaign(id:any) {
    let res = await getBasic(`campaign/id?id=${id}`,{});
    return res.result;
  }
  @action async initUser(){
   
  }
  @action async registerStaff(data:any) {
    let res = await postBasic(`campaign/registerStaff`,data);
    return res;
  }
}
