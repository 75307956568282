const userInfo = {
    "shop": "ร้านค้า",
    "userInfo": "ข้อมูลผู้ใช้",
    "personalInfo": "ข้อมูลส่วนตัว",
    "fullName": "ชื่อ",
    "nameSurname": "ชื่อ นามสกุล",
    "email": "อีเมล",
    "phoneNo": "โทรศัพท์",
    "password": "รหัสผ่าน",
    "confirmPassword": "ยืนยันรหัสผ่าน",
}

export default userInfo
