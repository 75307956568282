const shopInfo = {
    "shop": "ร้านค้า",
    "shopInfo": "ข้อมูลร้านค้า",
    "shopName": "ชื่อร้าน",
    "name": "ชื่อ",
    "shopCode": "รหัสร้าน",
    "desc": "รายละเอียด",
    "addDesc": "เพิ่มรายละเอียดที่นี่",
    "phoneNo": "โทรศัพท์",
    "shopAddress": "ที่อยู่",
    "line1": "บรรทัดที่ 1",
    "line2": "บรรทัดที่ 2",
    "compName": "ชื่อบริษัท",
    "taxId": "หมายเลขประจำตัวผู้เสียภาษี",
    "shopAdmin": "แอดมินร้านค้า",
}

export default shopInfo
