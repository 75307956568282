import i18n from "i18next";
import { observable, action, toJS, makeAutoObservable } from "mobx";

class TranslationStore {
  constructor() {
    makeAutoObservable(this)
  }
  @observable lang = 'th';

  @action
  changeLanguage = () => {
    switch (i18n.language) {
      case "en":
        i18n.changeLanguage("th");
        this.lang = i18n.language;
        break;
      case "th":
        i18n.changeLanguage("en");
        this.lang = i18n.language;
        break;
      default:
        break;
    }
  };
}

export default TranslationStore
