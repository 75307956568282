const shopInfo = {
    "shop": "Shop",
    "shopInfo": "Shop Information",
    "shopName": "Shop Name",
    "name": "Name",
    "shopCode": "Shop Code",
    "desc": "Descriptions",
    "addDesc": "Add description here",
    "phoneNo": "Phone Number",
    "shopAddress": "Shop Address",
    "line1": "Line 1",
    "line2": "Line 2",
    "compName": "Company Name",
    "taxId": "Tax ID",
    "shopAdmin": "Shop Admin",
}

export default shopInfo
